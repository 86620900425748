<template>
  <v-card
    :wxid="$options.name"
    :class="[{ disabled: isTileDisabled, unfocused: isTileUnfocused }, targetStatusClass]"
    class="tile product-speed-5m unit-name status-pictogram"
    tag="section"
    sf="2"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="title" class="primary-title">
        {{ title }}
        <v-icon v-if="!isTileAuthorized">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value">{{ primaryValue }}</h2>
      <p v-if="noConversionFound" class="value-sub-text__unit font-italic">{{ $t("tiles.noConversionNote") }}</p>
      <p v-else class="value-sub-text__unit">({{ $t("tiles.currentProductNote") }})</p>
      <section class="status-details">
        <dl>
          <dt>{{ targetType }}</dt>
          <dd class="d-flex align-center">
            <v-icon class="mr-1">mdi-target</v-icon>
            {{ targetValue }}
          </dd>
        </dl>
        <div class="pictogram" />
      </section>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import TileService from "@/components/dashboard/tiles/TileService";
import { getUnitName } from "@/components/dashboard/tileselectormenu/TileHelper";
import Helpers, { dash } from "@/helpers";
import UnitConversionWarningMixin from "@/components/dashboard/tiles/UnitConversionWarningMixin";

export default {
  name: "TileProductSpeed5m",
  extends: TileBase,
  inheritAttrs: false,
  mixins: [UnitConversionWarningMixin],
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", [
      "activeProductionUnitId",
      "productionUnitConvertedUnit",
      "currentProductSku",
      "currentProductSpeed5m",
      "currentProductPUAssociations",
      "currentProductProductionObjective",
    ]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    ...mapGetters("user", ["preferences"]),
    title() {
      return this.$t("tiles.productSpeed5m_unit", { unit: this.unitName });
    },
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.currentProductSpeed5m.requiredFeature);
    },
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    isTileDisabled() {
      return !this.isTileAuthorized;
    },
    value() {
      return this.currentProductSpeed5m(this.unit)
        ? Helpers.round(this.currentProductSpeed5m(this.unit), 1)
        : this.currentProductSpeed5m(this.unit);
    },
    primaryValue() {
      return this.value && this.isTileAuthorized ? this.value : dash;
    },
    unit() {
      const tile = TileService.getDashboardTile(this.activeProductionUnitId, this.tileIndex, this.preferences);
      return tile?.config?.product_unit ?? null;
    },
    unitName() {
      const unitName = this.unit
        ? getUnitName(this.unit).name
        : ProductionUnitService.getUnitName(this.productionUnitConvertedUnit);
      if (this.productionUnitConvertedUnit && this.isTileAuthorized) {
        return " - " + unitName + this.$t("common.perHour");
      } else {
        return "";
      }
    },
    targetType() {
      return this.$t("dashboard.targetInfo.product");
    },
    target() {
      const target = this.currentProductProductionObjective(this.unit);
      const precision = target >= 0.05 ? 1 : 4;
      return this.isTileAuthorized ? Helpers.round(target, precision) : null;
    },
    targetValue() {
      return this.target != null ? this.target : this.$t("common.nA");
    },
    targetStatusClass() {
      let statusClass;
      if (this.value && this.target && this.target > 0) {
        const valueAsPercent = 100.0 * (this.value / this.target);
        if (valueAsPercent >= 100.0) {
          statusClass = "success-state";
        } else if (valueAsPercent >= 90.0) {
          statusClass = "warning-state";
        } else {
          statusClass = "error-state";
        }
      }
      return statusClass;
    },
  },
};
</script>
