<template>
  <v-card
    :wxid="$options.name"
    :class="{ disabled: isTileDisabled, unfocused: isTileUnfocused }"
    class="tile production"
    tag="section"
    sf="1"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.completionLevel')" class="primary-title">
        {{ $t("tiles.completionLevel") }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>

      <fragment>
        <h2 class="primary-value">{{ showData ? completionPercent : dash }}</h2>
        <p v-if="showData" class="value-sub-text__unit count">
          {{ remainingQuantity }}
        </p>
        <section class="status-details">
          <dl>
            <dt>{{ $t("tiles.plannedQuantity") }}</dt>
            <dd>
              {{ showData ? plannedQuantity : dash }}
            </dd>
          </dl>
        </section>
      </fragment>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import { dash } from "@/helpers";
import { toCompletionPercent } from "@/components/ProductionRunCompletionService";
import { PRODUCTION_RUN } from "@/store/TimeUtils";

export default {
  name: "TileProduction",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  data() {
    return {
      dash: dash,
    };
  },
  computed: {
    ...mapGetters("dashboard", ["currentProductionRun", "productionUnitConvertedUnit", "currentProductionRunMetrics"]),
    ...mapGetters("navigation", ["isLiveData","timeFrame"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    showData() {
      return this.isLiveData || this.timeFrame == PRODUCTION_RUN;
    },
    completionPercent() {
      return this.completion?.completionPercent ?? dash;
    },
    remainingQuantity() {
      return this.completion?.remainingQuantity ? this.$t("tiles.remainingQuantity", [this.completion.remainingQuantity, this.unitName]) : dash;
    },
    completion() {
      return toCompletionPercent(
        this.currentProductionRun?.planned_quantity,
        this.currentProductionRunMetrics?.produced_quantity?.total_count,
        this.currentProductionRunMetrics?.reject_quantity?.total_count,
      );
    },
    unitName() {
      if (this.productionUnitConvertedUnit && this.isTileAuthorized()) {
        return ProductionUnitService.getUnitName(this.productionUnitConvertedUnit);
      } else {
        return "";
      }
    },
    plannedQuantity() {
      return this.currentProductionRun?.planned_quantity ?? dash;
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.production.requiredFeature);
    },
  },
};
</script>

<style lang="scss">
.wrap {
  overflow-wrap: normal;
}
</style>
