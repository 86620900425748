<template>
  <v-navigation-drawer
    v-model="isDrawerOpen"
    app
    right
    temporary
    hide-overlay
    class="pa-2"
    :width="isMobile ? '100%' : '375px'"
    :class="{ 'pt-16': isMobile }"
    style="background-color: var(--color-element-layer1); height: 100%"
  >
    <div class="d-flex flex-column justify-space-between pb-5" style="width: 375px; height: 100%">
      <div class="pt-1 pb-0 my-2 mx-2">
        <div class="d-flex pr-4 pl-6 mb-4">
          <div class="d-flex align-center">
            <wx-btn-icon v-if="windowIndex > 0" class="mr-2 ml-n1" text @click="back()">
              <v-icon>mdi-arrow-left</v-icon>
            </wx-btn-icon>
            <h2 class="font-weight-regular">
              {{ title }}
            </h2>
          </div>
          <v-spacer />
          <wx-btn-icon text @click="closeMenu">
            <v-icon>mdi-close</v-icon>
          </wx-btn-icon>
        </div>
        <v-window v-model="windowIndex">
          <v-window-item>
            <v-list nav>
              <v-list-item
                v-for="(group, mainLoop) in groupedItems"
                :key="mainLoop"
                :disabled="group.isLocked"
                :input-value="group.selected"
                @click="group.action(false)"
                color="primary"
              >
                <v-list-item-title class="d-flex align-center ml-2">
                  {{ group.name }}
                  <v-spacer />
                  <v-icon v-if="group.children">mdi-chevron-right</v-icon>
                  <v-icon v-if="group.selected && !group.isLocked && !group.children" color="primary">mdi-check</v-icon>
                  <v-icon v-if="group.isLocked" small>mdi-lock</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-window-item>
          <v-window-item v-for="(window, windowLoopIndex) in windows" :key="`window-${windowLoopIndex}`">
            <v-list nav>
              <v-list-item
                v-for="(item, itemLoopIndex) in window.children"
                :key="`graph-${itemLoopIndex}`"
                :disabled="item.isLocked"
                :input-value="item.selected"
                @click="item.action(false)"
                color="primary"
              >
                <v-list-item-title class="d-flex align-center ml-2">
                  {{ item.name }}
                  <v-spacer />
                  <v-icon v-if="item.children">mdi-chevron-right</v-icon>
                  <v-icon v-if="item.selected && !item.isLocked && !item.children" color="primary">mdi-check</v-icon>
                  <v-icon v-if="item.isLocked" small>mdi-lock</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-window-item>
        </v-window>
      </div>
      <!--   Footer section with WorxAcademy link   -->
      <div class="d-flex flex-column">
        <v-divider />
        <div class="d-flex justify-end mt-10 mr-10 mb-3">
          <a
            :href="$t('tiles.selectorMenu.academyLink.href')"
            :title="$t('tiles.selectorMenu.academyLink.hint')"
            class="d-flex font-weight-bold text-decoration-none align-center"
            target="kpiTiles"
            :class="{ 'wx-typo-md': isMobile, 'wx-typo-sm': !isMobile }"
          >
            {{ $t("tiles.selectorMenu.academyLink.text") }}
            <v-icon color="primary" class="ml-2">mdi-open-in-new</v-icon>
          </a>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import WxBtnIcon from "@/components/ui/WxBtnIcon.vue";
import {
  getConvertedUnitNameTiles,
  getLengthUnitNameTiles,
  getVolumeUnitNameTiles,
  getWeightUnitNameTiles,
} from "@/components/dashboard/tileselectormenu/TileHelper";
import {
  validLengthUnits,
  validProductUnits,
  validVolumeUnits,
  validWeightUnits,
} from "@/components/user/UserPreferencesService";
import Graphs from "@/components/Graphs";

export default {
  name: "GraphSelector",
  components: { WxBtnIcon },
  data() {
    return {
      windowIndex: 0,
      windows: [],
    };
  },
  watch: {
    isMenuOpen() {
      if (!this.isMenuOpen) {
        this.resetWindows();
      } else {
        this.navigateToSelectedTile(this.groupedItems);
      }
    },
  },
  computed: {
    ...mapGetters("dashboard", ["tileSelection", "activeDashboardPreferencesGraph", "productionUnitConvertedUnit"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature", "anyFactoryPuHasRequiredFeature"]),
    ...mapGetters("graphs", ["isMenuOpen"]),
    isDrawerOpen: {
      get() {
        return this.isMenuOpen;
      },
      set(value) {
        this.setIsMenuOpen(value);
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    title() {
      if (this.windowIndex === 0) return this.$t("tiles.selectorMenu.title");
      return this.windows[this.windowIndex - 1].categoryName;
    },
    groupedItems() {
      return [
        {
          name: this.$t("dashboard.panelHeader.oeeGraph"),
          isLocked: !this.isAuthorized(Graphs.oeeTrend),
          selected: this.isSelected(Graphs.oeeTrend),
          action: () => this.selectKpi(Graphs.oeeTrend),
        },
        {
          name: this.$t("graphs.selectorMenu.groupTitle.quantity"),
          isLocked: false,
          selected: this.isSelected(Graphs.quantity, Graphs.weight, Graphs.volume, Graphs.length),
          children: true,
          action: (instant) => {
            this.windows.push({
              categoryName: this.$t("graphs.selectorMenu.groupTitle.quantity"),
              children: [
                {
                  name: this.$t("tiles.quantity.totalUnits"),
                  isLocked: !this.isAuthorized(Graphs.quantity),
                  selected: this.isSelected(Graphs.quantity),
                  children: true,
                  action: (instant) => {
                    // display units if in dashboard
                    this.windows.push({
                      categoryName: this.$t("tiles.quantity.totalUnits"),
                      children: getConvertedUnitNameTiles(
                        Graphs.quantity,
                        this.isAuthorized,
                        this.isConfigSelected,
                        this.selectKpi,
                      ),
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
                {
                  name: this.$t("tiles.totalWeight"),
                  isLocked: !this.isAuthorized(Graphs.weight),
                  selected: this.isSelected(Graphs.weight),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.totalWeight"),
                      children: getWeightUnitNameTiles(Graphs.weight, this.isConfigSelected, this.selectKpi),
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
                {
                  name: this.$t("tiles.totalVolume"),
                  isLocked: !this.isAuthorized(Graphs.volume),
                  selected: this.isSelected(Graphs.volume),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.totalVolume"),
                      children: getVolumeUnitNameTiles(Graphs.volume, this.isConfigSelected, this.selectKpi),
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
                {
                  name: this.$t("tiles.totalLength"),
                  isLocked: !this.isAuthorized(Graphs.length),
                  selected: this.isSelected(Graphs.length),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.totalLength"),
                      children: getLengthUnitNameTiles(Graphs.length, this.isConfigSelected, this.selectKpi),
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
              ],
            });
            this.increaseWindowIndex(instant);
          },
        },
        {
          name: this.$t("graphs.selectorMenu.groupTitle.rejectQuantity"),
          isLocked: false,
          selected: this.isSelected(
            Graphs.rejectQuantity,
            Graphs.rejectWeight,
            Graphs.rejectVolume,
            Graphs.rejectLength,
          ),
          children: true,
          action: (instant) => {
            this.windows.push({
              categoryName: this.$t("graphs.selectorMenu.groupTitle.rejectQuantity"),
              children: [
                {
                  name: this.$t("tiles.quantity.totalUnits"),
                  isLocked: !this.isAuthorized(Graphs.rejectQuantity),
                  selected: this.isSelected(Graphs.rejectQuantity),
                  children: true,
                  action: (instant) => {
                    // display units if in dashboard
                    this.windows.push({
                      categoryName: this.$t("tiles.quantity.totalUnits"),
                      children: getConvertedUnitNameTiles(
                        Graphs.rejectQuantity,
                        this.isAuthorized,
                        this.isConfigSelected,
                        this.selectKpi,
                      ),
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
                {
                  name: this.$t("tiles.totalWeight"),
                  isLocked: !this.isAuthorized(Graphs.rejectWeight),
                  selected: this.isSelected(Graphs.rejectWeight),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.totalWeight"),
                      children: getWeightUnitNameTiles(Graphs.rejectWeight, this.isConfigSelected, this.selectKpi),
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
                {
                  name: this.$t("tiles.totalVolume"),
                  isLocked: !this.isAuthorized(Graphs.rejectVolume),
                  selected: this.isSelected(Graphs.rejectVolume),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.totalVolume"),
                      children: getVolumeUnitNameTiles(Graphs.rejectVolume, this.isConfigSelected, this.selectKpi),
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
                {
                  name: this.$t("tiles.totalLength"),
                  isLocked: !this.isAuthorized(Graphs.rejectLength),
                  selected: this.isSelected(Graphs.rejectLength),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.totalLength"),
                      children: getLengthUnitNameTiles(Graphs.rejectLength, this.isConfigSelected, this.selectKpi),
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
              ],
            });
            this.increaseWindowIndex(instant);
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions("graphs", ["setIsMenuOpen"]),
    ...mapActions("user", ["updateSelectedGraph"]),
    selectKpi(selectedKpi, tileConfig) {
      let graph = Graphs.quantity.name;
      let kpiConfig = tileConfig;
      switch (selectedKpi.name) {
        case Graphs.oeeTrend.name: {
          graph = Graphs.oeeTrend.name;
          kpiConfig = {};
          break;
        }
        case Graphs.quantity.name:
        case Graphs.rejectQuantity.name: {
          graph = selectedKpi.name;
          let productUnit = tileConfig.product_unit ? tileConfig.product_unit : this.productionUnitConvertedUnit;
          kpiConfig = {
            product_unit: productUnit,
          };
          break;
        }
        case Graphs.weight.name:
        case Graphs.volume.name:
        case Graphs.length.name:
        case Graphs.rejectWeight.name:
        case Graphs.rejectVolume.name:
        case Graphs.rejectLength.name: {
          graph = selectedKpi.name;
          kpiConfig = {
            product_unit: tileConfig.product_unit,
          };
          break;
        }
        default: {
          graph = Graphs.quantity.name;
          kpiConfig = {
            product_unit: "unit",
          };
        }
      }
      this.updateSelectedGraph({ newSelectedGraph: graph, kpiConfig: kpiConfig });
      this.setIsMenuOpen(false);
    },
    isSelected(...kpis) {
      if (!this.activeDashboardPreferencesGraph) return false;
      const currentKpi = this.activeDashboardPreferencesGraph.selected_kpi;
      return !!kpis.find((t) => t.name === currentKpi);
    },
    isAuthorized(graph) {
      return this.activePuHasRequiredFeature(graph.requiredFeature);
    },
    isConfigSelected(graph, config) {
      if (!this.activeDashboardPreferencesGraph) return false;
      let graphConfig = this.activeDashboardPreferencesGraph.config;

      if (!graphConfig) return false;

      let currentlySelectedGraphKpi = this.activeDashboardPreferencesGraph.selected_kpi;
      switch (currentlySelectedGraphKpi) {
        case Graphs.quantity.name:
        case Graphs.rejectQuantity.name: {
          let currentGraphProductUnit = graphConfig.product_unit
            ? graphConfig.product_unit
            : this.productionUnitConvertedUnit;
          let menuItemProductUnit = config.product_unit; // The menu item may not contain a `product_unit` property at all
          // Is the same KPI & unit? Example: Quantity in bottle is not the same as rejected quantity in bottle!
          return currentlySelectedGraphKpi === graph.name && currentGraphProductUnit === menuItemProductUnit;
        }
        case Graphs.weight.name:
        case Graphs.volume.name:
        case Graphs.length.name:
        case Graphs.rejectWeight.name:
        case Graphs.rejectVolume.name:
        case Graphs.rejectLength.name: {
          let currentGraphProductUnit = graphConfig.product_unit;
          let menuItemProductUnit = config.product_unit; // The menu item may not contain a `product_unit` property at all
          // Is the same KPI & unit? Example: Quantity in bottle is not the same as rejected quantity in bottle!
          return currentlySelectedGraphKpi === graph.name && currentGraphProductUnit === menuItemProductUnit;
        }
        case Graphs.oeeTrend: {
          return currentlySelectedGraphKpi === graph.name;
        }
        default: {
          return false;
        }
      }
    },
    isUnitCategorySelected(graph, category) {
      if (!this.activeDashboardPreferencesGraph) return false;
      let graphConfig = this.activeDashboardPreferencesGraph.config;

      const unit = graphConfig.product_unit; // Could be null
      if (category === "weight" && validWeightUnits.indexOf(unit) >= 0) return true;
      if (category === "length" && validLengthUnits.indexOf(unit) >= 0) return true;
      if (category === "volume" && validVolumeUnits.indexOf(unit) >= 0) return true;
      // this feels a bit hacky, but because we don't support selection of converted units in the overview,
      // if we're in the overview and 'product_unit' is null it means the converted unit option is selected.
      // otherwise it would have a unit like 'kg' or 'l'.
      if (category === "converted_units" && validProductUnits.indexOf(unit) >= 0) return true;

      return false;
    },
    closeMenu() {
      this.setIsMenuOpen(false);
    },
    back(n) {
      if (!n) {
        this.windowIndex -= 1;
        this.windows.pop();
      } else {
        for (let i = 0; i < n; i++) {
          this.windowIndex -= 1;
          this.windows.pop();
        }
      }
    },
    resetWindows() {
      this.windowIndex = 0;
      this.windows = [];
    },
    navigateToSelectedTile(items) {
      items.forEach((item) => {
        if (item.selected && item.children) {
          item.action(true);
          this.navigateToSelectedTile(this.windows[this.windowIndex - 1].children);
        }
      });
    },
    increaseWindowIndex(instant) {
      if (instant) {
        this.windowIndex += 1;
      } else {
        setTimeout(() => (this.windowIndex += 1), 25);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setIsMenuOpen(false);
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-navigation-drawer__content {
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  height: 100%;
}

.breadcrumb-item {
  cursor: pointer;
}
</style>
