import { mapActions, mapGetters } from "vuex";
import { getUnitName } from "@/components/dashboard/tileselectormenu/TileHelper";

export default {
  data() {
    return {
      noConversionFound: false,
    };
  },
  computed: {
    ...mapGetters("dashboard", ["currentProduct", "activeProductionUnit"]),
  },
  watch: {
    unit() {
      this.showWarningIfNeeded();
    },
    currentProduct(newVal, prevVal) {
      if (newVal && prevVal && newVal.id === prevVal.id) return;
      this.showWarningIfNeeded();
    },
    value(newVal, oldVal) {
      if (oldVal === null && newVal !== null) this.showWarningIfNeeded();
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationWarning"]),
    showWarningIfNeeded() {
      if (this.value === undefined) {
        this.noConversionFound = true;
        if (!this.currentProduct) {
          const unitName = getUnitName(this.activeProductionUnit.converted_unit_name).name;
          this.showOperationWarning({
            title: this.$t("tiles.noConversionWarning.title", { tile: this.title }),
            message: this.$t("tiles.noConversionWarning.noProductWarning", { unitName }),
            timeout: 10000,
          });
        } else {
          const unitName = getUnitName(this.unit).name;
          this.showOperationWarning({
            title: this.$t("tiles.noConversionWarning.title", { tile: this.title }),
            message: this.$t("tiles.noConversionWarning.productWarning", {
              unit: unitName,
              currentProductId: this.currentProduct.id,
            }),
            timeout: 10000,
          });
        }
      } else {
        this.noConversionFound = false;
      }
    },
  },
  mounted() {
    this.showWarningIfNeeded();
  },
};
