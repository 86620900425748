<template>
  <section :wxid="$options.name" class="giveaway-graph-container">
    <figure>
      <wx-chart-line-graph
        v-if="giveawayGraph && giveawayGraph.products.length > 0"
        css-classes="canvas-container"
        :chart-data="giveawayChartData"
        :chart-options="giveawayChartOptions"
      />
    </figure>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/i18n";
import PackageFeatures from "@/components/PackageFeatures";
import StyleValues from "@/styles/_values.scss";
import WxChartLineGraph from "@/components/ui/WxChartLineGraph.vue";

export default {
  name: "SpcGiveawayGraph",
  components: {
    WxChartLineGraph,
  },
  props: {
    productIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentMillisUTC: 0,
    };
  },
  computed: {
    ...mapGetters("user", ["theme"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("dashboard", ["giveawayGraph", "activeProductionUnit"]),
    isGiveawaySamplingModeActive() {
      if (!this.activeProductionUnit) return false;
      return (
        this.activePuHasRequiredFeature(PackageFeatures.giveaway) &&
        this.activeProductionUnit.giveaway_sampling_configuration !== null
      );
    },
    chartYMinMax() {
      const dataPoints = this.product.data_points;

      const initialMin = dataPoints.find(point => point.value !== null)?.value ?? 0.0;
      const initialMax = initialMin ?? 0.0;

      const dataMin = dataPoints.reduce((min, point) => {
        if (point.value === null) {
          return min;
        }
        return point.value < min ? point.value : min;
      }, initialMin);

      const dataMax = dataPoints.reduce((max, point) => {
        if (point.value === null) {
          return max;
        }
        return point.value > max ? point.value : max;
      }, initialMax);

      const min  = this.product.lower_tolerance ? Math.min(dataMin, this.product.lower_tolerance) : dataMin;
      const max  = this.product.upper_tolerance ? Math.max(dataMax, this.product.upper_tolerance) : dataMax;
      // Add 10% of total height in spacing above highest and lowest point
      const diff = (max - min) * 0.1; 
      return {
        min: min - diff,
        max: max + diff,
      };
    },
    product() {
      return this.giveawayGraph.products[this.productIndex];
    },
    graphLineColor() {
      return this.theme === "light" ? StyleValues.color_text_themeLight : StyleValues.color_text_themeDark;
    },
    graphBackgroundColor() {
      return this.theme === "light" ? StyleValues.color_control_themeLight : StyleValues.color_control_themeDark;
    },
    labelBackground() {
      return this.theme === "light"
        ? StyleValues.color_baseBackground_themeLight
        : StyleValues.color_baseBackground_themeDark;
    },
    toleranceErrorColor() {
      return this.theme === "light" ? StyleValues.color_errorLight : StyleValues.color_errorDark;
    },
    giveawayChartOptions() {
      return {
        animation: {
          duration: 0,
        },
        title: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false,
            grid: {
              display: false,
            },
            min: this.chartYMinMax.min,
            max: this.chartYMinMax.max,
          },
          x: {
            type: "linear",
            min: this.giveawayGraph.start.timestamp,
            max: this.giveawayGraph.end.timestamp,
            display: false,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          annotation: {
            annotations: {
              backgroundProgress: {
                type: "box",
                yMin: this.chartYMinMax.min,
                yMax: this.chartYMinMax.max,
                xMin: this.giveawayGraph.start.timestamp,
                xMax: Math.min(this.currentMillisUTC, this.giveawayGraph.end.timestamp),
                backgroundColor: this.graphBackgroundColor,
                borderWidth: 0,
                drawTime: "beforeDatasetsDraw",
              },
              target: this.product.target_value
                ? {
                    type: "line",
                    yMin: this.product.target_value,
                    yMax: this.product.target_value,
                    borderColor: this.graphLineColor,
                    borderWidth: 1,
                    label: {
                      content: [this.getTargetLabel(this.product.target_value, this.product.target_unit)],
                      font: { size: 15, weight: "normal" },
                      enabled: true,
                      position: "end",
                      color: this.graphLineColor,
                      backgroundColor: this.labelBackground,
                      yAdjust: 1,
                      xAdjust: 6,
                      textAlign: "left",
                    },
                    drawTime: "beforeDatasetsDraw",
                  }
                : null,
              lowerTolerance: this.product.lower_tolerance
                ? {
                    type: "line",
                    yMin: this.product.lower_tolerance,
                    yMax: this.product.lower_tolerance,
                    borderColor: this.toleranceErrorColor,
                    borderWidth: 1,
                    borderDash: [3, 3],
                    label: {
                      content: [`Min. ${this.getTargetLabel(this.product.lower_tolerance, this.product.target_unit)}`],
                      font: { size: 15, weight: "normal" },
                      enabled: true,
                      position: "end",
                      color: this.graphLineColor,
                      backgroundColor: this.labelBackground,
                      yAdjust: 1,
                      xAdjust: 6,
                      textAlign: "left",
                    },
                    drawTime: "beforeDatasetsDraw",
                  }
                : null,
              upperTolerance: this.product.upper_tolerance
                ? {
                    type: "line",
                    yMin: this.product.upper_tolerance,
                    yMax: this.product.upper_tolerance,
                    borderColor: this.toleranceErrorColor,
                    borderWidth: 1,
                    borderDash: [3, 3],
                    label: {
                      content: [`Max. ${this.getTargetLabel(this.product.upper_tolerance, this.product.target_unit)}`],
                      font: { size: 15, weight: "normal" },
                      enabled: true,
                      position: "end",
                      color: this.graphLineColor,
                      backgroundColor: this.labelBackground,
                      yAdjust: 1,
                      xAdjust: 6,
                      textAlign: "left",
                    },
                    drawTime: "beforeDatasetsDraw",
                  }
                : null,
              backgroundDivider: {
                type: "line",
                xMin: this.currentMillisUTC,
                xMax: this.currentMillisUTC,
                borderColor: this.graphLineColor,
                borderWidth: 1,
                label: {
                  enabled: false,
                },
              },
            },
          },
          tooltip: {
            mode: "index",
            intersect: true,
            position: "nearest",
            enabled: true,
            displayColors: false,
            callbacks: {
              title: function title() {
                return i18n.t("tiles.productGiveaway");
              },
              label: function oeePointLabel(tooltipItem) {
                const dataset = tooltipItem.dataset;
                const unit = dataset.utils[tooltipItem.dataIndex].unit.toLowerCase();
                const y = dataset.data[tooltipItem.dataIndex].value;
                // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
                return `${y.toFixed(2)} ${i18n.t(`common.units.${unit}`)}`;
              },
              footer: function oeePointFooter(tooltipItems) {
                let tooltipItem = tooltipItems != null && tooltipItems instanceof Array ? tooltipItems[0] : null;
                if (tooltipItem) {
                  let dataset = tooltipItem.dataset;
                  if (dataset) {
                    const date = dataset.utils[tooltipItem.dataIndex].date;
                    const time = dataset.utils[tooltipItem.dataIndex].time;
                    return date + "\n" + time;
                  }
                }
                return "";
              },
            },
          },
        },
      };
    },
    giveawayChartData() {
      const pointBackgroundColors = this.product.data_points.map((s) =>
        s.value > this.product.upper_tolerance || s.value < this.product.lower_tolerance
          ? this.toleranceErrorColor
          : this.graphLineColor,
      );
      const utils = this.product.data_points.map((s) => {
        return {
          unit: s.unit,
          date: s.date,
          time: s.time,
        };
      });
      return {
        labels: this.product.data_points.map(() => ""),
        datasets: [
          {
            order: 0,
            radius: 3,
            pointRadius: this.isGiveawaySamplingModeActive ? 3 : 0,
            pointHitRadius: 3,
            pointHoverBackgroundColor: pointBackgroundColors,
            pointBackgroundColor: pointBackgroundColors,
            pointBorderColor: this.graphLineColor,
            borderColor: this.graphLineColor,
            borderWidth: 2,
            backgroundColor: "transparent",
            tension: 0.1,
            data: this.product.data_points.map((s) => {
              return {
                x: s.timestamp,
                y: this.getDataYValue(s.value),
                value: s.value,
              };
            }),
            utils,
          },
        ],
      };
    },
  },
  methods: {
    getDataYValue(value) {
      if (!this.product.target_value || !value) return value;
      if (value >= this.product.target_value) {
        return Math.min(value, this.chartYMinMax.max);
      } else {
        return Math.max(value, this.chartYMinMax.min);
      }
    },
    getTargetLabel(value, unit) {
      const lowerCaseUnit = unit.toLowerCase();
      const unitLabel = this.getUnitLabel(lowerCaseUnit);
      return `${value.toFixed(1)} ${unitLabel}`;
    },
    setCurrentTimeUTC() {
      this.currentMillisUTC = new Date(new Date().toISOString()).getTime();
      setInterval(() => {
        this.currentMillisUTC = new Date(new Date().toISOString()).getTime();
      }, 60000);
    },
    getUnitLabel(unit) {
      switch (unit) {
        case "kilogram":
          return this.$t("common.units.kilogram");
        case "gram":
          return this.$t("common.units.gram");
        case "ton":
          return this.$t("common.units.ton");
        case "pound":
          return this.$t("common.units.pound");
        case "ounce":
          return this.$t("common.units.ounce");
        case "meter":
          return this.$t("common.units.meter");
        case "feet":
          return this.$t("common.units.feet");
        case "liter":
          return this.$t("common.units.liter");
        default:
          return this.$t("common.units.unit");
      }
    },
  },
  mounted() {
    this.setCurrentTimeUTC();
  },
};
</script>

<style lang="scss" scoped>
.giveaway-graph-container {
  flex: 1 0 auto;
  position: relative;
  overflow: hidden;
  margin-top: 10px;

  .canvas-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    ::v-deep canvas {
      height: 100%;
      width: 100%;
    }
  }
  .x-axe-lines {
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid currentColor;

    &.target-line {
      color: var(--color-text-theme);
      padding-right: 4px;
      text-align: right;
    }
    &.treshold-line {
      color: var(--color-error);
      border-top-width: 2px;
      border-top-style: dashed;
      padding-right: 4px;
      text-align: right;

      &.maximum {
        top: 0;
      }
      &.minimum {
        bottom: 0;
      }
    }
  }
}
</style>
