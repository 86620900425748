<template>
  <v-navigation-drawer
    v-model="isDrawerOpen"
    app
    right
    temporary
    hide-overlay
    class="pa-2"
    :width="isMobile ? '100%' : '375px'"
    :class="{ 'pt-16': isMobile }"
    style="background-color: var(--color-element-layer1); height: 100%"
  >
    <div class="d-flex flex-column justify-space-between pb-5" style="width: 375px; height: 100%">
      <div class="pt-1 pb-0 my-2 mx-2">
        <div class="d-flex pr-4 pl-6 mb-4">
          <div class="d-flex align-center">
            <wx-btn-icon v-if="windowIndex > 0" class="mr-2 ml-n1" text @click="back()">
              <v-icon>mdi-arrow-left</v-icon>
            </wx-btn-icon>
            <h2 class="font-weight-regular">
              {{ title }}
            </h2>
          </div>
          <v-spacer />
          <wx-btn-icon text @click="closeMenu">
            <v-icon>mdi-close</v-icon>
          </wx-btn-icon>
        </div>
        <v-window v-model="windowIndex">
          <v-window-item>
            <v-list nav>
              <!-- /* use of visibleOverviewKpiGroup for temporary solution to filter out kpi not implemented yet in overview */ -->
              <v-list-item
                v-for="(group, mainLoop) in isOverview ? visibleOverviewKpiGroup : groupedItems"
                :key="mainLoop"
                :disabled="group.isLocked"
                @click="group.action(false)"
                :input-value="group.selected"
                color="primary"
              >
                <v-list-item-title class="d-flex align-center ml-2">
                  {{ group.name }}
                  <v-spacer />
                  <v-icon v-if="group.children">mdi-chevron-right</v-icon>
                  <v-icon v-if="group.selected && !group.isLocked && !group.children" color="primary">mdi-check</v-icon>
                  <v-icon v-if="group.isLocked" small>mdi-lock</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-window-item>
          <!-- /* use of visibleOverviewKpiChildren for temporary solution to filter out kpi not implemented yet in overview */ -->
          <v-window-item
            v-for="(window, windowLoopIndex) in isOverview ? visibleOverviewKpiChildren : windows"
            :key="`window-${windowLoopIndex}`"
          >
            <v-list nav>
              <v-list-item
                v-for="(item, itemLoopIndex) in window.children"
                :key="`tile-${itemLoopIndex}`"
                :disabled="item.isLocked"
                @click="item.action(false)"
                :input-value="item.selected"
                color="primary"
              >
                <v-list-item-title class="d-flex align-center ml-2">
                  {{ item.name }}
                  <v-spacer />
                  <v-icon v-if="item.children">mdi-chevron-right</v-icon>
                  <v-icon v-if="item.selected && !item.isLocked && !item.children" color="primary">mdi-check</v-icon>
                  <v-icon v-if="item.isLocked" small>mdi-lock</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-window-item>
        </v-window>
      </div>
      <!--   Footer section with WorxAcademy link   -->
      <div class="d-flex flex-column">
        <v-divider />
        <div class="d-flex justify-end mt-10 mr-10 mb-3">
          <a
            :href="$t('tiles.selectorMenu.academyLink.href')"
            :title="$t('tiles.selectorMenu.academyLink.hint')"
            class="d-flex font-weight-bold text-decoration-none align-center"
            target="kpiTiles"
            :class="{ 'wx-typo-md': isMobile, 'wx-typo-sm': !isMobile }"
          >
            {{ $t("tiles.selectorMenu.academyLink.text") }}
            <v-icon color="primary" class="ml-2">mdi-open-in-new</v-icon>
          </a>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Tiles from "@/components/Tiles";
import WxBtnIcon from "@/components/ui/WxBtnIcon.vue";
import {
  getConvertedUnitNameTiles, getLengthUnitNameTiles,
  getVolumeUnitNameTiles,
  getWeightUnitNameTiles,
} from "@/components/dashboard/tileselectormenu/TileHelper";
import {
  validLengthUnits,
  validProductUnits,
  validVolumeUnits,
  validWeightUnits,
} from "@/components/user/UserPreferencesService";

export default {
  name: "TileSelector",
  components: { WxBtnIcon },
  data() {
    return {
      windowIndex: 0,
      windows: [],
    };
  },
  props: {
    isOverview: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isMenuOpen() {
      if (!this.isMenuOpen) {
        this.resetWindows();
      } else {
        this.navigateToSelectedTile(this.groupedItems);
      }
    },
    selectedTileIndex(curr, prev) {
      if (curr === null || (curr >= 0 && prev === null)) return;
      this.windowIndex = 0;
      this.windows = [];
      this.navigateToSelectedTile(this.groupedItems);
    },
  },
  computed: {
    ...mapGetters("dashboard", ["tileSelection", "giveawayViewToggle", "activeDashboardPreferencesTiles"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature", "anyFactoryPuHasRequiredFeature"]),
    ...mapGetters("tiles", ["isMenuOpen", "selectedTileIndex"]),
    ...mapGetters("overview", ["activeKpi", "activeKpiConfig"]),
    isDrawerOpen: {
      get() {
        return this.isMenuOpen;
      },
      set(value) {
        this.setIsMenuOpen(value);
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    title() {
      if (this.windowIndex === 0) return this.$t("tiles.selectorMenu.title");
      return this.windows[this.windowIndex - 1].categoryName;
    },
    groupedItems() {
      if (this.selectedTileIndex === null) return [];
      return [
        {
          name: this.$t("tiles.selectorMenu.tilesGroupTitle.availability"),
          isLocked: false,
          selected: this.isSelected(Tiles.availability, Tiles.totalUptime, Tiles.totalDowntime),
          children: true,
          action: (instant) => {
            this.windows.push({
              categoryName: this.$t("tiles.selectorMenu.tilesGroupTitle.availability"),
              children: [
                {
                  name: this.$t("tiles.availabilityPercent"),
                  isLocked: !this.isAuthorized(Tiles.availability),
                  selected: this.isSelected(Tiles.availability),
                  action: () => this.selectKpi(Tiles.availability, null),
                },
                {
                  name: this.$t("tiles.totalUptime"),
                  isLocked: !this.isAuthorized(Tiles.totalUptime),
                  selected: this.isSelected(Tiles.totalUptime),
                  action: () => this.selectKpi(Tiles.totalUptime),
                },
                {
                  name: this.$t("tiles.totalDowntimeSelector"),
                  isLocked: !this.isAuthorized(Tiles.totalDowntime),
                  selected: this.isSelected(Tiles.totalDowntime),
                  action: () => this.selectKpi(Tiles.totalDowntime),
                },
                {
                  name: this.$t("tiles.inProgressAndToJustifyDowntime"),
                  isLocked: !this.isAuthorized(Tiles.inProgressDowntime),
                  selected: this.isSelected(Tiles.inProgressDowntime),
                  action: () => this.selectKpi(Tiles.inProgressDowntime),
                },
              ],
            });
            this.increaseWindowIndex(instant);
          },
        },
        {
          name: this.$t("tiles.selectorMenu.tilesGroupTitle.performance"),
          isLocked: false,
          selected: this.isSelected(
            Tiles.performance,
            Tiles.currentProductThroughput,
            Tiles.currentProductThroughputPerMinute,
            Tiles.currentProductSpeed5m,
            Tiles.currentProductSpeed5mPerMinute,
          ),
          children: true,
          action: (instant) => {
            this.windows.push({
              categoryName: this.$t("tiles.selectorMenu.tilesGroupTitle.performance"),
              children: [
                {
                  name: this.$t("tiles.performancePercent"),
                  isLocked: !this.isAuthorized(Tiles.performance),
                  selected: this.isSelected(Tiles.performance),
                  action: () => this.selectKpi(Tiles.performance),
                },
                {
                  name: this.$t("tiles.productThroughputPerHour"),
                  isLocked: !this.isAuthorized(Tiles.currentProductThroughput),
                  selected: this.isSelected(Tiles.currentProductThroughput),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.productThroughputPerHour"),
                      children: [
                        {
                          name: this.$t("tiles.convertedUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductThroughput, "converted_units"),
                          children: !this.isOverview,
                          action: (instant) => {
                            // don't show units in overview for now
                            if (this.isOverview) return this.selectKpi(Tiles.currentProductThroughput);
                            // display units if in dashboard
                            this.windows.push({
                              categoryName: this.$t("tiles.convertedUnits"),
                              children: getConvertedUnitNameTiles(
                                Tiles.currentProductThroughput,
                                this.isAuthorized,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "hour" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.weightUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductThroughput, "weight"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.weightUnits"),
                              children: getWeightUnitNameTiles(
                                Tiles.currentProductThroughput,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "hour" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.lengthUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductThroughput, "length"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.lengthUnits"),
                              children: getLengthUnitNameTiles(
                                Tiles.currentProductThroughput,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "hour" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.volumeUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductThroughput, "volume"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.volumeUnits"),
                              children: getVolumeUnitNameTiles(
                                Tiles.currentProductThroughput,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "hour" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                      ],
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
                {
                  name: this.$t("tiles.productThroughputPerMinute"),
                  isLocked: !this.isAuthorized(Tiles.currentProductThroughputPerMinute),
                  selected: this.isSelected(Tiles.currentProductThroughputPerMinute),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.productThroughputPerMinute"),
                      children: [
                        {
                          name: this.$t("tiles.convertedUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductThroughputPerMinute, "converted_units"),
                          children: !this.isOverview,
                          action: (instant) => {
                            // don't show units in overview for now
                            if (this.isOverview) return this.selectKpi(Tiles.currentProductThroughputPerMinute);
                            // display units if in dashboard
                            this.windows.push({
                              categoryName: this.$t("tiles.convertedUnits"),
                              children: getConvertedUnitNameTiles(
                                Tiles.currentProductThroughputPerMinute,
                                this.isAuthorized,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "minute" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.weightUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductThroughputPerMinute, "weight"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.weightUnits"),
                              children: getWeightUnitNameTiles(
                                Tiles.currentProductThroughputPerMinute,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "minute" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.lengthUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductThroughputPerMinute, "length"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.lengthUnits"),
                              children: getLengthUnitNameTiles(
                                Tiles.currentProductThroughputPerMinute,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "minute" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.volumeUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductThroughputPerMinute, "volume"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.volumeUnits"),
                              children: getVolumeUnitNameTiles(
                                Tiles.currentProductThroughputPerMinute,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "minute" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                      ],
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
                {
                  name: this.$t("tiles.productSpeed5mPerHour"),
                  isLocked: !this.isAuthorized(Tiles.currentProductSpeed5m),
                  selected: this.isSelected(Tiles.currentProductSpeed5m),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.productSpeed5mPerHour"),
                      children: [
                        {
                          name: this.$t("tiles.convertedUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductSpeed5m, "converted_units"),
                          children: !this.isOverview,
                          action: (instant) => {
                            // don't show units in overview for now
                            if (this.isOverview) return this.selectKpi(Tiles.currentProductSpeed5m);
                            // display units if in dashboard
                            this.windows.push({
                              categoryName: this.$t("tiles.convertedUnits"),
                              children: getConvertedUnitNameTiles(
                                Tiles.currentProductSpeed5m,
                                this.isAuthorized,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "hour" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.weightUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductSpeed5m, "weight"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.weightUnits"),
                              children: getWeightUnitNameTiles(
                                Tiles.currentProductSpeed5m,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "hour" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.lengthUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductSpeed5m, "length"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.lengthUnits"),
                              children: getLengthUnitNameTiles(
                                Tiles.currentProductSpeed5m,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "hour" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.volumeUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductSpeed5m, "volume"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.volumeUnits"),
                              children: getVolumeUnitNameTiles(
                                Tiles.currentProductSpeed5m,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "hour" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                      ],
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
                {
                  name: this.$t("tiles.productSpeed5mPerMinute"),
                  isLocked: !this.isAuthorized(Tiles.currentProductSpeed5mPerMinute),
                  selected: this.isSelected(Tiles.currentProductSpeed5mPerMinute),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.productSpeed5mPerMinute"),
                      children: [
                        {
                          name: this.$t("tiles.convertedUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductSpeed5mPerMinute, "converted_units"),
                          children: !this.isOverview,
                          action: (instant) => {
                            // don't show units in overview for now
                            if (this.isOverview) return this.selectKpi(Tiles.currentProductSpeed5mPerMinute);
                            // display units if in dashboard
                            this.windows.push({
                              categoryName: this.$t("tiles.convertedUnits"),
                              children: getConvertedUnitNameTiles(
                                Tiles.currentProductSpeed5mPerMinute,
                                this.isAuthorized,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "minute" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.weightUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductSpeed5mPerMinute, "weight"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.weightUnits"),
                              children: getWeightUnitNameTiles(
                                Tiles.currentProductSpeed5mPerMinute,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "minute" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.lengthUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductSpeed5mPerMinute, "length"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.lengthUnits"),
                              children: getLengthUnitNameTiles(
                                Tiles.currentProductSpeed5mPerMinute,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "minute" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.volumeUnits"),
                          selected: this.isUnitCategorySelected(Tiles.currentProductSpeed5mPerMinute, "volume"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.volumeUnits"),
                              children: getVolumeUnitNameTiles(
                                Tiles.currentProductSpeed5mPerMinute,
                                this.isConfigSelected,
                                this.selectKpi,
                                { time_unit: "minute" },
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                      ],
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
              ],
            });
            this.increaseWindowIndex(instant);
          },
        },
        {
          name: this.$t("tiles.selectorMenu.tilesGroupTitle.quality"),
          isLocked: false,
          selected: this.isSelected(Tiles.quality, Tiles.rejectQuantity),
          children: true,
          action: (instant) => {
            this.windows.push({
              categoryName: this.$t("tiles.selectorMenu.tilesGroupTitle.quality"),
              children: [
                {
                  name: this.$t("tiles.qualityPercent"),
                  isLocked: !this.isAuthorized(Tiles.quality),
                  selected: this.isSelected(Tiles.quality),
                  action: () => this.selectKpi(Tiles.quality),
                },
                {
                  name: this.$t("tiles.netQuantity.name"),
                  isLocked: !this.isAuthorized(Tiles.rejectQuantity),
                  selected: this.isSelected(Tiles.rejectQuantity),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.netQuantity.name"),
                      children: [
                        {
                          name: this.$t("tiles.convertedUnits"),
                          selected: this.isUnitCategorySelected(Tiles.rejectQuantity, "converted_units"),
                          children: !this.isOverview,
                          action: (instant) => {
                            // don't show units in overview for now
                            if (this.isOverview) return this.selectKpi(Tiles.rejectQuantity);
                            // display units if in dashboard
                            this.windows.push({
                              categoryName: this.$t("tiles.convertedUnits"),
                              children: getConvertedUnitNameTiles(
                                Tiles.rejectQuantity,
                                this.isAuthorized,
                                this.isConfigSelected,
                                this.selectKpi,
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.weightUnits"),
                          selected: this.isUnitCategorySelected(Tiles.rejectQuantity, "weight"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.weightUnits"),
                              children: getWeightUnitNameTiles(
                                Tiles.rejectQuantity,
                                this.isConfigSelected,
                                this.selectKpi,
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.lengthUnits"),
                          selected: this.isUnitCategorySelected(Tiles.rejectQuantity, "length"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.lengthUnits"),
                              children: getLengthUnitNameTiles(
                                Tiles.rejectQuantity,
                                this.isConfigSelected,
                                this.selectKpi,
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                        {
                          name: this.$t("tiles.volumeUnits"),
                          selected: this.isUnitCategorySelected(Tiles.rejectQuantity, "volume"),
                          children: true,
                          action: (instant) => {
                            this.windows.push({
                              categoryName: this.$t("tiles.volumeUnits"),
                              children: getVolumeUnitNameTiles(
                                Tiles.rejectQuantity,
                                this.isConfigSelected,
                                this.selectKpi,
                              ),
                            });
                            this.increaseWindowIndex(instant);
                          },
                        },
                      ],
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
              ],
            });
            this.increaseWindowIndex(instant);
          },
        },
        {
          name: this.$t("tiles.oee"),
          isLocked: !this.isAuthorized(Tiles.oee),
          selected: this.isSelected(Tiles.oee),
          action: () => this.selectKpi(Tiles.oee),
        },
        {
          name: this.$t("tiles.ooe"),
          isLocked: !this.isAuthorized(Tiles.ooe),
          selected: this.isSelected(Tiles.ooe),
          action: () => this.selectKpi(Tiles.ooe),
        },
        {
          name: this.$t("tiles.selectorMenu.tilesGroupTitle.productionRunCompletion"),
          isLocked: false,
          selected: this.isSelected(Tiles.timeToCompletion, Tiles.production),
          children: true,
          hideInOverview: false /* temporary solution to filter out kpi not implemented yet in overview */,
          action: (instant) => {
            this.windows.push({
              categoryName: this.$t("tiles.selectorMenu.tilesGroupTitle.productionRunCompletion"),
              children: [
                {
                  name: this.$t("tiles.timeToCompletion.title"),
                  isLocked: !this.isAuthorized(Tiles.timeToCompletion),
                  selected: this.isSelected(Tiles.timeToCompletion),
                  action: () => this.selectKpi(Tiles.timeToCompletion),
                },
                {
                  name: this.$t("tiles.completionLevel"),
                  isLocked: !this.isAuthorized(Tiles.production),
                  selected: this.isSelected(Tiles.production),
                  action: () => this.selectKpi(Tiles.production),
                },
              ],
            });
            this.increaseWindowIndex(instant);
          },
        },
        {
          name: this.$t("tiles.selectorMenu.tilesGroupTitle.quantity"),
          isLocked: false,
          selected: this.isSelected(
            Tiles.currentProductQuantity,
            Tiles.allProductsQuantity,
            Tiles.totalWeight,
            Tiles.totalLength,
            Tiles.totalVolume,
          ),
          children: true,
          action: (instant) => {
            this.windows.push({
              categoryName: this.$t("tiles.selectorMenu.tilesGroupTitle.quantity"),
              children: [
                {
                  name: this.$t("tiles.currentProductQuantity"),
                  isLocked: !this.isAuthorized(Tiles.currentProductQuantity),
                  selected: this.isSelected(Tiles.currentProductQuantity),
                  children: !this.isOverview,
                  action: (instant) => {
                    // don't show units in overview for now
                    if (this.isOverview) return this.selectKpi(Tiles.currentProductQuantity);
                    // display units if in dashboard
                    this.windows.push({
                      categoryName: this.$t("tiles.currentProductQuantity"),
                      children: getConvertedUnitNameTiles(
                        Tiles.currentProductQuantity,
                        this.isAuthorized,
                        this.isConfigSelected,
                        this.selectKpi,
                        { scope: "current_product" },
                      ),
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
                {
                  name: this.$t("tiles.quantity.totalUnits"),
                  isLocked: !this.isAuthorized(Tiles.allProductsQuantity),
                  selected: this.isSelected(Tiles.allProductsQuantity),
                  children: !this.isOverview,
                  action: (instant) => {
                    // don't show units in overview for now
                    if (this.isOverview) return this.selectKpi(Tiles.allProductsQuantity);
                    // display units if in dashboard
                    this.windows.push({
                      categoryName: this.$t("tiles.quantity.totalUnits"),
                      children: getConvertedUnitNameTiles(
                        Tiles.allProductsQuantity,
                        this.isAuthorized,
                        this.isConfigSelected,
                        this.selectKpi,
                        { scope: "all_products" },
                      ),
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
                {
                  name: this.$t("tiles.totalWeight"),
                  isLocked: !this.isAuthorized(Tiles.totalWeight),
                  selected: this.isSelected(Tiles.totalWeight),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.totalWeight"),
                      children: getWeightUnitNameTiles(Tiles.totalWeight, this.isConfigSelected, this.selectKpi),
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
                {
                  name: this.$t("tiles.totalVolume"),
                  isLocked: !this.isAuthorized(Tiles.totalVolume),
                  selected: this.isSelected(Tiles.totalVolume),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.totalVolume"),
                      children: getVolumeUnitNameTiles(Tiles.totalVolume, this.isConfigSelected, this.selectKpi),
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
                {
                  name: this.$t("tiles.totalLength"),
                  isLocked: !this.isAuthorized(Tiles.totalLength),
                  selected: this.isSelected(Tiles.totalLength),
                  children: true,
                  action: (instant) => {
                    this.windows.push({
                      categoryName: this.$t("tiles.totalLength"),
                      children: getLengthUnitNameTiles(Tiles.totalLength, this.isConfigSelected, this.selectKpi),
                    });
                    this.increaseWindowIndex(instant);
                  },
                },
              ],
            });
            this.increaseWindowIndex(instant);
          },
        },
        {
          name: this.$t("tiles.selectorMenu.tilesGroupTitle.giveaway"),
          isLocked: false,
          selected: this.isSelected(Tiles.productGiveawayPercent, Tiles.productGiveaway, Tiles.productAverageGiveaway),
          children: true,
          action: (instant) => {
            this.windows.push({
              categoryName: this.$t("tiles.selectorMenu.tilesGroupTitle.giveaway"),
              children: [
                {
                  name: this.$t("tiles.productGiveawayPercent"),
                  isLocked: !this.isAuthorized(Tiles.productGiveawayPercent),
                  selected: this.isSelected(Tiles.productGiveawayPercent),
                  action: () => this.selectKpi(Tiles.productGiveawayPercent),
                },
                {
                  name: this.$t("tiles.productGiveaway"),
                  isLocked: !this.isAuthorized(Tiles.productGiveaway),
                  selected: this.isSelected(Tiles.productGiveaway),
                  action: () => this.selectKpi(Tiles.productGiveaway),
                },
                {
                  name: this.$t("tiles.productAverageGiveaway"),
                  isLocked: !this.isAuthorized(Tiles.productAverageGiveaway),
                  selected: this.isSelected(Tiles.productAverageGiveaway),
                  action: () => this.selectKpi(Tiles.productAverageGiveaway),
                },
              ],
            });
            this.increaseWindowIndex(instant);
          },
        },
        {
          name: this.$t("tiles.selectorMenu.tilesGroupTitle.cycles"),
          isLocked: false,
          selected: this.isSelected(Tiles.cycleTime, Tiles.cycleQuantity),
          children: true,
          hideInOverview: true /* temporary solution to filter out kpi not implemented yet in overview */,
          action: (instant) => {
            this.windows.push({
              categoryName: this.$t("tiles.selectorMenu.tilesGroupTitle.cycles"),
              children: [
                {
                  name: this.$t("tiles.currentAverageCycleTimeSelector"),
                  isLocked: !this.isAuthorized(Tiles.cycleTime),
                  selected: this.isSelected(Tiles.cycleTime),
                  action: () => this.selectKpi(Tiles.cycleTime),
                },
                {
                  name: this.$t("tiles.cycleQuantitySelector"),
                  isLocked: !this.isAuthorized(Tiles.cycleQuantity),
                  selected: this.isSelected(Tiles.cycleQuantity),
                  action: () => this.selectKpi(Tiles.cycleQuantity),
                },
              ],
            });
            this.increaseWindowIndex(instant);
          },
        },
      ];
    },
    // temporary solution to filter out kpi not implemented yet in overview
    visibleOverviewKpiGroup() {
      return this.groupedItems.filter((item) => !item.hideInOverview);
    },
    // temporary solution to filter out kpi not implemented yet in overview
    visibleOverviewKpiChildren() {
      return this.windows.map((category) => ({
        ...category,
        children: category.children.filter((child) => !child.hideInOverview),
      }));
    },
  },
  methods: {
    ...mapActions("dashboard", ["setTileByIndex"]),
    ...mapActions("overview", ["setActiveKpi"]),
    ...mapActions("tiles", ["setIsMenuOpen", "setSelectedTileIndex"]),
    ...mapActions("user", ["updateSelectedOverviewKpi"]),
    selectKpi(selectedKpi, tileConfig) {
      if (this.isOverview) {
        this.setActiveKpi({ activeKpi: selectedKpi.name, config: tileConfig });
        this.updateSelectedOverviewKpi({ kpiName: selectedKpi.name, config: tileConfig });
      } else {
        if (this.selectedTileIndex === null) return;
        this.setTileByIndex({
          tile: selectedKpi.name,
          index: this.selectedTileIndex,
          optionalTileConfig: tileConfig ?? {},
        });
      }

      this.setIsMenuOpen(false);
    },
    isSelected(...tiles) {
      if (this.isOverview) {
        return !!tiles.find((t) => t.name === this.activeKpi);
      } else {
        if (this.selectedTileIndex === null) return false;
        const currentTile = this.tileSelection[this.selectedTileIndex];
        return !!tiles.find((t) => t.name === currentTile);
      }
    },
    isAuthorized(tile) {
      if (this.isOverview) {
        return this.anyFactoryPuHasRequiredFeature(tile.requiredFeature);
      } else if (this.selectedTileIndex === null) return false;
      return this.activePuHasRequiredFeature(tile.requiredFeature);
    },
    isConfigSelected(tile, config) {
      if (!this.isSelected(tile)) return false;

      let tileConfig;
      if (this.isOverview) {
        tileConfig = this.activeKpiConfig;
      } else {
        if (!this.activeDashboardPreferencesTiles) return false;
        tileConfig = this.activeDashboardPreferencesTiles[this.selectedTileIndex]?.config;
      }
      if (!tileConfig) return false;
      let isEqual = true;
      Object.keys(config).forEach((key) => {
        // the current tile does not have the key in its config, or it does not have the same value
        if (!tileConfig[key] || tileConfig[key] !== config[key]) isEqual = false;
      });
      return isEqual;
    },
    isUnitCategorySelected(tile, category) {
      if (!this.isSelected(tile)) return false;

      let tileConfig;
      if (this.isOverview) {
        tileConfig = this.activeKpiConfig;
      } else {
        if (!this.activeDashboardPreferencesTiles) return false;
        tileConfig = this.activeDashboardPreferencesTiles[this.selectedTileIndex]?.config;
      }

      const unit = tileConfig.product_unit;
      if (category === "weight" && validWeightUnits.indexOf(unit) >= 0) return true;
      if (category === "length" && validLengthUnits.indexOf(unit) >= 0) return true;
      if (category === "volume" && validVolumeUnits.indexOf(unit) >= 0) return true;
      // this feels a bit hacky, but because we don't support selection of converted units in the overview,
      // if we're in the overview and 'product_unit' is null it means the converted unit option is selected.
      // otherwise it would have a unit like 'kg' or 'l'.
      if (category === "converted_units" && !this.isOverview && validProductUnits.indexOf(unit) >= 0) return true;
      if (category === "converted_units" && this.isOverview && !unit) return true;

      return false;
    },
    closeMenu() {
      this.setIsMenuOpen(false);
    },
    back(n) {
      if (!n) {
        this.windowIndex -= 1;
        this.windows.pop();
      } else {
        for (let i = 0; i < n; i++) {
          this.windowIndex -= 1;
          this.windows.pop();
        }
      }
    },
    resetWindows() {
      this.windowIndex = 0;
      this.windows = [];
      this.setSelectedTileIndex(null);
    },
    navigateToSelectedTile(items) {
      items.forEach((item) => {
        if (item.selected && item.children) {
          item.action(true);
          this.navigateToSelectedTile(this.windows[this.windowIndex - 1].children);
        }
      });
    },
    increaseWindowIndex(instant) {
      if (instant) {
        this.windowIndex += 1;
      } else {
        setTimeout(() => (this.windowIndex += 1), 25);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setIsMenuOpen(false);
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-navigation-drawer__content {
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  height: 100%;
}

.breadcrumb-item {
  cursor: pointer;
}
</style>
