<template>
  <v-card
    :wxid="$options.name"
    :class="[{ disabled: isTileDisabled, unfocused: isTileUnfocused }, targetStatusClass]"
    class="tile oee status-pictogram"
    tag="section"
    sf="3"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.oee')" class="primary-title">
        {{ $t("tiles.oee") }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value">{{ primaryValue }}</h2>
      <section class="status-details">
        <dl>
          <dt>{{ targetType }}</dt>
          <dd class="d-flex align-center">
            <v-icon class="mr-1">mdi-target</v-icon>
            {{ targetValue }}
          </dd>
        </dl>
        <div class="pictogram" />
      </section>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers from "@/helpers";

export default {
  name: "TileOee",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", ["oee", "oeeTarget"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    // OEE
    primaryValue() {
      return this.oee !== null && this.isTileAuthorized() ? Helpers.round(this.oee, 1) + "%" : "—";
    },
    targetValue() {
      if (this.oeeTarget) {
        return Helpers.round(this.oeeTarget, 1) + "%";
      } else {
        return this.$t("common.nA");
      }
    },
    targetType() {
      return this.$t("dashboard.targetInfo.line");
    },
    targetStatusClass() {
      let statusClass;
      if (this.oeeTarget && this.oee) {
        const value = this.oee - this.oeeTarget;
        if (value >= 0) {
          statusClass = "success-state";
        } else if (value >= -5) {
          statusClass = "warning-state";
        } else {
          statusClass = "error-state";
        }
      }
      return statusClass;
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.oee.requiredFeature);
    },
  },
};
</script>
