<template>
  <v-dialog v-model="dialog" overlay-color="var(--color-overlayBackground)" width="unset">
    <template #activator="{ on }">
      <wx-btn-icon class="legend-btn" v-on="on" :title="$t('graphLegend.buttonHoverTitle')">
        <v-icon>mdi-information-outline</v-icon>
      </wx-btn-icon>
    </template>

    <div class="content">
      <v-btn icon large @click="dialog = false" :title="$t('common.closeWindowHoverTitle')" class="close-btn">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <h2 class="wx-typo-h1">{{ $t("graphLegend.title") }}</h2>

      <v-list color="transparent" disabled>
        <v-list-item v-for="(item, i) in items" :key="i">
          <v-list-item-icon>
            <v-icon :small="isMobile" v-if="item.icon" :class="item.class">{{ item.icon }}</v-icon>
            <div
              v-else
              :class="['no-icon', item.class]"
              :style="item.class.startsWith('colored-dot') ? 'color: ' + item.color : ''"
            ></div>
          </v-list-item-icon>
          <v-list-item-content>
            {{ item.text }}
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="actions">
        <wx-btn-standard color="primary" @click="dialog = false" :title="$t('common.closeWindowHoverTitle')">
          {{ $t("common.close") }}
        </wx-btn-standard>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import WxBtnIcon from "@/components/ui/WxBtnIcon";

export default {
  name: "LegendOeeGraphDialog",

  components: {
    WxBtnStandard,
    WxBtnIcon,
  },

  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    items() {
      return [
        {
          text: this.$t("legend.active"),
          class: "colored-dot",
          color: "var(--color-uptime)",
        },
        {
          text: this.$t("legend.plannedDowntime"),
          class: "colored-dot",
          color: "var(--color-plannedDowntime)",
        },
        {
          text: this.$t("legend.unjustifiedDowntime"),
          class: "colored-dot",
          color: "var(--color-unjustifiedDowntime)",
        },
        {
          text: this.$t("legend.justifiedDowntime"),
          class: "colored-dot",
          color: "var(--color-justifiedDowntime)",
        },
        /* {
          text: this.$t("legend.lowThroughput"),
          class: "colored-dot",
          color: "var(--color-unjustifiedLowThroughput)",
        },
        {
          text: this.$t("legend.justifiedLowThroughput"),
          class: "colored-dot",
          color: "var(--color-justifiedLowThroughput)",
        }, */
        {
          text: this.$t("legend.disconnection"),
          class: "colored-dot",
          color: "var(--color-disconnected)",
        },
        {
          text: this.$t("legend.outOfProduction"),
          class: "colored-dot",
          color: "var(--color-outOfProduction)",
        },
        {
          text: this.$t("legend.noData"),
          class: "colored-dot no-data",
          color: "var(--color-unknown)",
        },
        {
          text: this.$t("legend.kpiValue"),
          icon: "$graphIcon",
        },
        {
          text: this.$t("legend.kpiObjective"),
          class: "oeeObjective",
        },
        {
          text: this.$t("legend.shiftChange"),
          icon: "$shiftChangeIcon",
        },
        {
          text: this.$t("legend.upcomingTime"),
          class: "upcomingTime",
        },
        {
          text: this.$t("justification.noProduction"),
          class: "early-shift-finish",
          icon: "$shiftChangeIcon",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  min-width: 256px;
  background: var(--color-flat-panel-modal-theme);
  margin: 0;

  @media ($wx-sm-min) {
    max-width: 540px;
  }

  @media ($wx-md-min) {
    max-width: 720px;
  }
}

.early-shift-finish {
  opacity: 0.3;
}

.close-btn {
  position: absolute;
  top: var(--dialog-close-offset);
  right: var(--dialog-close-offset);
}

.content {
  position: relative;
  width: auto;
  max-width: 100%;
  padding: var(--dialog-padding);
}

.v-list {
  @media ($wx-sm-min) {
    display: flex;
    flex-flow: row wrap;
    padding: 20px 0;
  }

  @media ($wx-md-min) {
    padding: 24px 0;
  }

  .v-list-item {
    padding: 0;
    min-height: 28px;

    @media ($wx-sm-min) {
      width: 50%;
      flex: 0 0 auto;
    }

    @media ($wx-md-min) {
      min-height: 32px;
    }

    @media ($wx-lg-min) {
      min-height: 38px;
    }

    @media ($wx-xl-min) {
      min-height: 42px;
    }

    .v-list-item__icon {
      align-self: center;
      margin: 0 12px 0 0;
    }

    .v-list-item__content {
      padding: 0;
    }
  }
}

.no-icon {
  position: relative;
  width: 16px;
  height: 16px;

  @media ($wx-md-min) {
    width: 24px;
    height: 24px;
  }

  &.colored-dot {
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: currentColor;

      @media ($wx-md-min) {
        width: 16px;
        height: 16px;
      }
    }

    .theme--dark & {
      &.no-data:before {
        border: 1px solid white;
      }
    }
  }

  &.oeeObjective:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
    border-top: 2px dashed var(--color-error);
  }

  &.upcomingTime {
    background-image: var(--repeating-oblique-small-pattern);
  }
}

.actions {
  text-align: center;
}
</style>
